// Styling for the navbar
#mainNav {
    //background-color: $gray-900;
    background-color: $white;

    .navbar-toggler {
        @include heading-font;

        //background-color: $primary;
        background-color: $gray-200;
        border: 0;
        color: white;
        font-size: 12px;
        padding: 13px;
        right: 0;
        text-transform: uppercase;
    }

    .navbar-brand {
        //color: $primary;
        color: $gray-900;

        &.active,
        &:active,
        &:focus,
        &:hover {
            //color: darken($primary, 10%);
            color: $primary;
        }
    }

    .navbar-nav {
        .nav-item {
            .nav-link {
                @include heading-font;

                //color: white;
                color: $gray-900;
                font-size: 90%;
                font-weight: 400;
                letter-spacing: 1px;
                padding: 0.75em 0;

                &.active,
                &:hover {
                    color: $primary;
                }
            }
        }
    }
}

@media(min-width:992px) {
    #mainNav {
        background-color: transparent;
        border: none;
        padding-top: 25px;
        padding-bottom: 25px;
        -webkit-transition: padding-top 0.3s, padding-bottom 0.3s;
        -moz-transition: padding-top 0.3s, padding-bottom 0.3s;
        transition: padding-top 0.3s, padding-bottom 0.3s;

        .navbar-brand {
            font-size: 1.75em;
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            transition: all 0.3s;
        }

        .navbar-nav {
            .nav-item {

                .nav-link {
                    margin: 0 1em;
                    background-image: none;
                    background-position: 0 88%;
                    background-repeat: no-repeat;
                    background-size: 100% 0.2em;
                    padding: .5em 1em;
                    transition: background-size 0.05s ease-in;

                    &.active,
                    &:hover {
                        background-image: linear-gradient(120deg, $primary 0%, lighten($primary, 5%) 100%);
                        background-size: 100% 88%;
                        background-blend-mode: lighten;
                        color: #ffffff;
                    }
                }
            }
        }

        &.navbar-shrink {
            //background-color: $gray-900;
            background-color: $white;
            padding-bottom: 0;
            padding-top: 0;

            .navbar-brand {
                font-size: 1.25em;
                padding: 12px 0;
            }
        }
    }
}
