@import
// Core variables and mixins
"variables",
"mixins.scss",

// vendors
"vendors/bootstrap/bootstrap",
"vendors/font-awesome/font-awesome",

// Global CSS
"global",

// Components
"navbar",
"masthead",
//"services.scss",
//"timeline.scss",
"team",
"contact",
"footer",
"register";


section.bg-dark {
    background-color: $gray-900;

    .section-heading {
        color: $white;
    }
}

#intro {
    padding-bottom: 0;
}
