// Variables

// Restated Bootstrap Variables

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$brand-color-2011: #c1bc2d;
$brand-color-2012: #3684cc;
$brand-color-2013: #ff9900;
$brand-color-2014: #993333;
$brand-color-2015: #00cc00;
$brand-color-2016: #9c27b0;
$brand-color-2017: #00bcd4;
$brand-color-2018: #3f51b5;
$brand-color-2019: #cddc39;
$brand-color-2020: #009688;

$brand-color-a: #f44336;
$brand-color-b: #e91e63;
$brand-color-c: #673ab7;
$brand-color-d: #3f51b5;
$brand-color-e: #2196f3;
$brand-color-f: #03a9f4;
$brand-color-g: #00bcd4;
$brand-color-i: #009688; // <- conny
$brand-color-j: #4caf50;
$brand-color-k: #8bc34a;
$brand-color-l: #607d8b;
$brand-color-m: #9e9e9e;
$brand-color-n: #795548;
$brand-color-o: #ff5722;
$brand-color-p: #ffc107;
$brand-color-q: #ffeb3b; // <- conny

$primary: $brand-color-2020;
$secondary: $brand-color-q;

$color-selection-text: #ffffff;
