.form-group-label {
    display: none;
}

section.bg-dark {
    background-color: $gray-900;

    .section-heading {
        color: $white;
    }
}

#intro {
    padding-bottom: 0;
}

.map {
    height: 20rem;
}

#register {
    textarea {
        height: 191px;
    }
}

.help-block {
    color: $gray-600;

    .is-invalid & {
        color: $warning;
    }

    ul {
        list-style: none;
        padding: 0;
    }
}
